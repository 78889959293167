


#footer {
	clear: both;
	color: #333;
	margin: 0 auto 0px;
	text-align: center;
	width: 100%;
	background-color: #ddd;
}
.footer {
	width: 100%;
	background-color: #ddd;
	min-height: 200px;
	border-top: 1px solid #eee;
	padding:20px 0;
}
.footer a {
	outline: none;
	color: #333;
}
.logo a {
	background: url(../images/logo.svg) no-repeat;
	display: block;
	height: 90px;
	text-indent: -9999px;
	width: 180px;
	background-size: cover;
	margin-bottom: 20px;
}
.logo-laval a {
	background: url(../images/logo-laval.svg) no-repeat;
	display: block;
	height: 90px;
	text-indent: -9999px;
	width: 180px;
	background-size: cover;
	margin-bottom: 20px;
}
.logo-west-island a {
	background: url(../images/logo-west-island.svg) no-repeat;
	display: block;
	height: 90px;
	text-indent: -9999px;
	width: 180px;
	background-size: cover;
	margin-bottom: 20px;
}
.logo-town-of-mount-royal a {
	background: url(../images/logo-town-of-mount-royal.svg) no-repeat;
	display: block;
	height: 90px;
	text-indent: -9999px;
	width: 180px;
	background-size: cover;
	margin-bottom: 20px;
}
.footlogo {
	width: 30%;
	margin: 0px;
	display: inline-block;
	text-align: left;
	vertical-align: top;
	padding-top: 30px;
}
.footnav {
	font-size: 12px;
	width: 65%;
	margin: 0px;
	display: inline-block;
	text-align: right;
	vertical-align: top;
}
.footer_menu_column {
	width: 30%;
	display: inline-block;
	vertical-align: top;
	text-align: left;
	margin-left: 5px;
}
.footer_menu_row {
	min-height: 95px;
}
.footnav h4 {
	font-size: 18px;
	margin-bottom: 0px;
}
.footnav ul {
	list-style: none;
	display: inline;
	padding-inline-start:0;
}
.footnav ul li {
	display: block;
	list-style: none;
	margin-bottom: 3px;
	font-size: 13px;
}
.footnav li a {
	/* border-right: 1px solid #484848; */
	color: #484848;
	padding: 0 7px 0 4px;
	text-decoration: none;
}
.footer a:hover {
	color: #891731;
	text-decoration: none;
}
.copyright {
	font-size: 12px;
	clear: both;
	text-align: center;
	margin-bottom: 0px;
	padding-bottom: 5px;
}
.copyright a {
	color: #484848;
	text-decoration: none;
}
@media screen and (max-width: 830px) {
	.logo_middle {
		width: 336px;
		height: 110px;
		margin: 10px auto;
		display: block;
		background: url(../images/logo.svg) no-repeat;
		background-size: cover; 
	}
}
@media screen and (max-width: 675px) {
	.logo_middle {
		width: 336px;
		height: 110px;
		margin: 10px auto;
		display: block;
		background: url(../images/logo.svg) no-repeat;
		background-size: cover; 
	}
	.footnav {
		width: 90%;
		margin: 0px auto;
		text-align: center;
	}
	.footlogo {
		width: 100%;
		margin: 0px;
		display: inline-block;
		text-align: center;
	}
	.footlogo .logo {
		display: inline-block;
		vertical-align: top;
		width: 100%;
		margin: 0 auto;
	}
	.footlogo .logo-laval {
		display: inline-block;
		vertical-align: top;
		width: 100%;
		margin: 0 auto;
	}.footlogo .logo-west-island {
		display: inline-block;
		vertical-align: top;
		width: 100%;
		margin: 0 auto;
	}
	.footlogo .logo-town-of-mount-royal {
		display: inline-block;
		vertical-align: top;
		width: 100%;
		margin: 0 auto;
	}
	.logo a {
		margin: 0 auto;
	}.logo-laval a {
		margin: 0 auto;
	}.logo-west-island a {
		margin: 0 auto;
	}
	.logo-town-of-mount-royal a {
		margin: 0 auto;
	}
	.footcorporate {
		width: 100%;
		display: inline-block;
		margin-top: 10px;
		text-align: center;
	}
	.footer_menu_column {
		width: 100%;
		text-align: center;
	}
	.youtube {
		width: 100%;
		height: 250px;
		margin-bottom: 10px;
	}
	.kit_pack {
		width: 100%;
		margin-bottom: 10px;
	}
	.footer_menu_row {
		height: 100%;
	}
	.fancy-header {
		padding: 0 15px;
	}
	.internal-banner {
		height: 185px;
	}
	.google-map-container {
		width:100%; 
		height:400px;
	}
	.logo_middle {
		width: 180px;
		height: 110px;
		margin: 10px auto;
		display: block;
		background: url(../images/logo.svg) no-repeat;
		background-size: cover; 
	}
	.logo_middle-laval {
		width: 180px;
		height: 110px;
		margin: 10px auto;
		display: block;
		background: url(../images/logo-laval.svg) no-repeat;
		background-size: cover; 
	}
	.logo_middle-west-island {
		width: 180px;
		height: 110px;
		margin: 10px auto;
		display: block;
		background: url(../images/logo-west-island.svg) no-repeat;
		background-size: cover; 
	}
	.logo_middle-town-of-mount-royal {
		width: 180px;
		height: 110px;
		margin: 10px auto;
		display: block;
		background: url(../images/logo-town-of-mount-royal.svg) no-repeat;
		background-size: cover; 
	}
	.footer_starter {
		background-color: #c2baba;
		background: url(../images/fcgm_hero_light_small.jpg) no-repeat center center;
		background-size: cover;
		width: 100%;
		min-height: 380px;
		padding: 20px 0px 20px 0px;
		color: #000;
		border-top: 1px solid #ccc;
	}
	.footnav li a {
		font-size: 16px;
	}
}
.footer_starter {
	background-color: #c2baba;
	background: url(../images/fcgm_hero_light.jpg) no-repeat center center;
	  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
	width: 100%;
	min-height: 380px;
	padding: 20px 0px 20px 0px;
	color: #000;
	border-top: 1px solid #ccc;
}
.call-to {
	padding: 10px 20px;
	width: 250px;
	font-size: 20px;
	margin-top: 30px;
	display: inline-block;
	font-weight: 300;
}
.button {
	margin: 5px;
	padding: 5px 10px;
	text-align: center;
	border-radius: 5px;
	color: #fff;
	text-shadow: 1px 1px #891731;
	text-decoration: none;
	border: 1px solid #891731;
	background-color: #891731;
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #891731), color-stop(1, #891731));
	background-image: -webkit-linear-gradient(top, #891731, #891731);
	background-image: -moz-linear-gradient(top, #891731, #891731);
	filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#891731', EndColorStr='#891731')";
	background-image: -ms-linear-gradient(top, #891731, #891731);
	background-image: -o-linear-gradient(top, #891731, #891731);
	background-image: linear-gradient(top, #891731, #891731);
	cursor: pointer;
	font-weight: 300;
}
.button:hover,
.button:focus {
	background: #891731;
	color: #fff;
	text-transform: none;
	text-decoration: none;
}
.btn-hca {
	text-align: center;
	border-radius:5px;
	padding: 6px 12px;
	font-family: Gilroy-Regular,sans-serif;
	font-weight:700;
	color: #fff;
	font-size: 18px;
	text-shadow: 1px 1px #891731;
	text-decoration: none;
	border: 1px solid #891731;
	background-color: #891731;
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #891731), color-stop(1, #891731));
	background-image: -webkit-linear-gradient(top, #891731, #891731);
	background-image: -moz-linear-gradient(top, #891731, #891731);
	filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#891731', EndColorStr='#891731')";
	background-image: -ms-linear-gradient(top, #891731, #891731);
	background-image: -o-linear-gradient(top, #891731, #891731);
	background-image: linear-gradient(top, #891731, #891731);
	cursor: pointer;
	text-transform: uppercase;
	margin: 15px 0;
}
.btn-hca:hover,
.btn-hca:focus {
	color: #fff;
	opacity: .85
}
.logo_middle {
	width: 336px;
	height: 110px;
	margin: 10px auto;
	display: block;
	background: url(../images/logo.svg) no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.logo_middle-laval {
	width: 336px;
	height: 110px;
	margin: 10px auto;
	display: block;
	background: url(../images/logo-laval.svg) no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.logo_middle-west-island {
	width: 336px;
	height: 110px;
	margin: 10px auto;
	display: block;
	background: url(../images/logo-west-island.svg) no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.logo_middle-town-of-mount-royal {
	width: 336px;
	height: 110px;
	margin: 10px auto;
	display: block;
	background: url(../images/logo-town-of-mount-royal.svg) no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.footer_icon_sponsors {
    display: flex;
    justify-content: space-evenly;
    margin: 35px 0;
    align-items: center;
    flex-wrap: wrap;
}
.border-section a {
  text-decoration: none;
}

.iframe-container {
  overflow: hidden;
}