.blog-page {
	background-color: rgb(249, 248, 246);
    font-size: 18px;
    overflow: hidden;
	.blog-hero {
	    position: relative;
	    text-align: center;
		margin: 100px 0 60px;
		@include media-down(sm) {
			margin: 50px 0 40px;
		}
		.title {
			font-family: 'Roboto Slab', serif;
			letter-spacing: -4px;
			font-size: 85px;
			@include media-down(sm) {
				font-size: 45px;
			}
		}
		.subtitle {
			font-family: 'Nanum Gothic', sans-serif;
			font-size: 14px;
			font-weight: 100;
			letter-spacing: 2px;
			opacity: .4;
			text-transform: uppercase;
			line-height: 21px;
			margin: 15px 0 0;
			@include media-down(sm) {
				margin-bottom: 40px;
			}
		}
		.category-nav {
			margin-top: 110px;
			@include media-down(sm) {
				display: none;
			}
			ul {
				display: flex;
				justify-content: center;
				li {
					text-transform: capitalize;
					align-self: center;
					margin: 0 20px;
					position: relative;
					text-align: center;
					list-style: none;
					&:last-child:after{
						content: none;
					}
					&:after {
						background: #121212;
						border-radius: 100%;
						content: "";
						display: block;
						height: 2px;
						position: absolute;
						right: -20px;
						top: 50%;
						width: 2px;
						opacity: 0.4;
					}
					a {
						color: #272c2f;
						opacity: .4;
						a.active {
							opacity: 1;
						}
					}
				}
			}
		}
		.featured-post{
			margin-top: 60px;
			text-align: left;
			@include media-down(sm) {
				margin: 0 -20px;
				position: relative;
				width: calc(100% + 40px);
			}
			
			a {
				text-decoration: none;
				color: #000;
				img {
					width: 100%;
				}
				.featured-post-excerpt {
					bottom: 60px;
					margin: 0;
					position: absolute;
					right: 0;
					width: 580px;
					clear: both;
					line-height: 28px;
					padding: 30px 30px 15px;
					background: hsla(0,0%,100%,.85);
					@include media-down(md) {
						bottom: 15px;
						padding: 15px 15px 15px;
					}
					@include media-down(sm) {
						clear: both;
						line-height: 28px;
						padding: 30px 30px 15px;
						background: hsla(0,0%,100%,.85);
						bottom: 0;
						top: 0;
						width: 100%;
						position: relative;
					}
					.category {
						font-family: 'Nanum Gothic', sans-serif;
						font-size: 14px;
						font-weight: 100;
						letter-spacing: 2px;
						opacity: .4;
						text-transform: uppercase;
					}
					.title {
						font-family: 'Roboto Slab', serif;
						font-size: inherit;
						font-weight: 100;
						font-size: 28px;
						letter-spacing: -.03em;
						line-height: 32px;
						margin: 10px 0 20px;
						&:hover {
							text-decoration: underline;
						}
						@include media-down(sm) {
							font-size: 22px;
						}
					}
				}
			}
		}
	}
	.h1-bold {
		font-family: 'Roboto Slab', serif;
	    font-size: 52px;
	    letter-spacing: -4px;
	    font-size: 45px;
	    font-weight: 100;
	    color: rgb(39, 44, 47);
	    line-height: 1.1;
		@include media-down(sm) {
			font-size: 40px;
		}
	}
	.category-description {
	    color: rgba(39, 44, 47, 0.4);
	    margin: 10px 0px 0px;
	}

	.category-recent-posts {
		.intro {
			h2 {
				text-transform: capitalize;
				font-family: 'Roboto Slab', serif;
				font-size: 36px;
				font-weight: 100;
				color: #272c2f;
				letter-spacing: -2px;
				@include media-down(sm) {
					font-size: 30px;
				}
			}
			p {
				display: inline-block;
				font-family: 'Nanum Gothic', sans-serif;
				color: rgba(39,44,47,.4);
				margin: 10px 0 0;
				@include media-down(sm) {
					display: block;
					font-size: 16px;
					margin-top: 0px;
				}
			}
			.see-all {
				float: right;
				color: #003845;
				display: block;
				font-weight: bold;
				margin: 10px 0 0;
				text-decoration: none;
				@include media-down(sm) {
					float: left;
					margin-top: 0;
					margin-bottom: 15px;
					display: block;
				}
			}
		}
	}
}
.post-below-content {
	text-align: center;
	.post-share-buttons-header {
		font-family: 'Roboto Slab', serif;
		
		margin-top: 80px;
		font-weight: 600;
		font-size: 18px;
		.post-content a {
			color: #ffb21a;
			text-decoration: none;
		}
	}
	.share-buttons {
		margin-top: 20px;
		margin-bottom: 40px;
		a i {
			margin: 0 5px;
			color: #121212;
			font-size: 30px;
		}
	}
}
.recent-posts {
	clear: both;
	margin-top: 40px;
	& > div > div {
		margin-bottom: 20px;
		a {
			color: #000;
			text-decoration: none;
		}
		.post-image {
			display: block;
			height: 0px;
			padding-bottom: 60%;
			position: relative;
			width: 100%;
			overflow: hidden;
			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
				left: 0px;
				position: absolute;
				top: 0px;

			}
		}
		.post-excerpt {
			clear: both;
			line-height: 28px;
			background: rgb(255, 255, 255);
			padding: 30px 30px 15px;
			@include media-down(sm) {
				padding: 15px 15px 15px;
			}
			.category {
				font-family: 'Nanum Gothic', sans-serif;
				font-size: 14px;
				font-weight: 100;
				letter-spacing: 2px;
				opacity: 0.4;
				text-transform: uppercase;
			}
			.title {
				font-family: 'Roboto Slab', serif;
				font-weight: 100;
				font-size: 28px;
				letter-spacing: -0.03em;
				line-height: 32px;
				margin: 10px 0px 20px;
				&:hover {
					text-decoration: underline;
				}
				@include media-down(sm) {
					font-size: 25px;
				}
			}
		}
	}
}
.related-posts-container {
	overflow: hidden;
	.related-posts-header {

		margin-top: 80px;
		display: flex;
		justify-content: space-between;
    	font-weight: bold;
		.h5-bold {
			font-size: 18px;
		}
		.pull-right {
    		align-self: flex-end;
			color: #003845;
			text-decoration: none;
    	}
	}
	.related-posts {
    	margin: 40px 0 100px;
		display: flex;
		justify-content: space-between;
		line-height: 30px;
		margin: 40px -25px 100px 0;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		& > div {
			min-width: 250px;
			margin: 0 10px;
			max-width: 380px;
			width: 32%;
			max-width: 32%;
		}
		.image-section {
			background: #f2efeb;
			height: 180px;
			width: 100%;
			background-position: center;
			background-size: cover;
			display: block;
			margin-bottom: 20px;
		}
		.post-category {
			font-family: 'Nanum Gothic', sans-serif;
			font-size: 14px;
			font-weight: 100;
			letter-spacing: 2px;
			opacity: .4;
			text-transform: uppercase;
		}
		.post-excerpt-title {
			font-family: 'Roboto Slab', serif;
			font-size: inherit;
			font-weight: 100;
			font-size: 28px;
			letter-spacing: -.03em;
			line-height: 32px;
			margin: 10px 0 20px;
			a {
				color: #272c2f;
			}
		}
		p {
			letter-spacing: 1px;
			list-style: 1.6;
			font-size: 18px;
			font-weight: 300;
		}
	}

}


.banner-section.blog-post {
	margin-top: 130px;
	img {
		max-width: 100%;
	}
} 
.blog-section {
	.blog-wrapper {
	 padding: 0 20px;
	}
	.blog-wrapper {
		h1 {
			display: inline-block;
			width: 80%;
			font-family: 'Roboto Slab', serif;
			font-size: 58px;
			color: #272c2f;
			letter-spacing: -2px;
			margin-top: 52px;
			padding: 0 25px;
			font-weight: bold;
			@include media-down(sm) {
				font-size: 30px;
				width: 100%;
			}
		}
		div.subtitle {
			font-size: 30px;
			line-height: 1.3;
			margin: 35px auto 0;
			max-width: 870px;
			padding: 0 25px;
			@include media-down(sm) {
				font-size: 20px;
			}
		}
		.author {
			font-family: 'Nanum Gothic', sans-serif;
			margin: 50px 0;
			font-size: 18px;
			font-weight: 400;
			letter-spacing: 2px;
			text-transform: uppercase;
			margin-top: 28px;
			opacity: .4;
			padding: 0 25px;
			text-transform: capitalize;
			@include media-down(sm) {
				margin: 20px 0;
			}
		}
		.content {
			text-align: left;
			width: 75%;
			display: inline-block;
			@include media-down(sm) {
				width: 100%;
			}
			a {
				color: #003845;
			}
			h2 {
				margin: 0 0 .5em;
				font-family: 'Roboto Slab', serif;
				font-size: 30px;
			}
			h3 {
				margin: 0 0 .5em;
				font-family: 'Roboto Slab', serif;
				font-size: 24px;
			}
			p, ol {
				font-size: 20px;
				line-height: 1.5;
    			margin: 0 0 1.2em;
			}
			ul {
				margin-top: 20px;
				li  {
					font-size: 20px;
					line-height: 1.2;
	    			margin: 0 0 0.8em;
				}
			}
			h4 {
				padding: 1em 0 2em;
				text-align: center;
				font-size: 34px!important;
				color: #7cb7b5;
				line-height: 54px;
				margin: 0 -16.5%;
				@include media-down(sm) {
			        margin: 0 0;
			        font-size: 24px!important;
			        line-height: 32px;
		            }
			}
		}
	}
}

.hidden-post {
	display: none;
}
.load-more-button{
	margin-bottom: 60px;
}